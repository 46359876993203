import React, {useRef} from 'react';
import "./UploadFileSmall.css";
import uploadLogo from '../assets/upload.svg';

interface UploadFileSmallProps {
    handleMediaChange: (e: React.ChangeEvent<HTMLInputElement>, networkKey?: string, index?: number) => void;
    resetMedia: (index: number) => void;
    media: string;
    index: number;
    networkKey?: string;
    disabled: boolean;
}

const UploadFileSmall: React.FC<UploadFileSmallProps> = ({ handleMediaChange, resetMedia, media, index, networkKey , disabled}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    return (
        <div className="upload-file-small">
            <img src={uploadLogo} width="20" height="20" alt="upload-logo" className={'upload-img-small'}/>
            <span className="upload-file-btn-small">Ajouter un fichier</span>
            <span className="upload-file-accepted-small">{".jpg acceptés"}</span>
            <input
                type="file"
                name={'mediaCarousel'}
                accept={'image/jpg, image/jpeg'}
                onChange={(e) => handleMediaChange(e, networkKey, index)}
                onClick={(e) => e.stopPropagation()}
                ref={fileInputRef}
                id={`file-input-small-${index}-${networkKey}`}
                disabled={disabled}
            />
            {media && (
                <div className={'small-media-loaded'}>
                    <button className="btn btn-close-media-small"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                resetMedia(index)
                            }}></button>
                    <img className={'media-loaded-picture-small'} src={media} alt={`Media ${index}`}/>
                </div>
            )}
        </div>
    );
};

export default UploadFileSmall;
