import AWS from 'aws-sdk';
import { s3Config } from "./s3Config";

const s3 = new AWS.S3(s3Config);
function getSignedURLEndpoint(key: string) {
    if (!key || key.trim().length === 0) {
        console.error('Invalid S3 key:', key);
        return '';
    }
    const options =  {
        Bucket: process.env.REACT_APP_AWS_BUCKET as string,
        Key: key,
        Expires: 60 * 30,
    };

    return s3.getSignedUrl('getObject', options);
}

export default getSignedURLEndpoint;