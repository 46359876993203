import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
    isAuthenticated: boolean;
    isAdmin: boolean;
    id: number | null;
    name: string | null;
    token: string | null;
    quota: number | null;
}

const initialState: UserState = {
    isAuthenticated: false,
    isAdmin: false,
    id: null,
    name: null,
    token: null,
    quota: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{
            isAuthenticated: boolean,
            isAdmin: boolean,
            id: number,
            name: string,
            token: string,
            quota: number,
        }>) => {
            state.isAuthenticated = true;
            state.isAdmin = action.payload.isAdmin;
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.token = action.payload.token;
            state.quota = action.payload.quota;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.isAdmin = false;
            state.id = null;
            state.name = null;
            state.token = null;
            state.quota = null;
        }
    },
});

export const { setUser } = userSlice.actions;
export const { logout } = userSlice.actions;

export default userSlice.reducer;