import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers, selectFullyConnectedUsers, selectPartiallyConnectedUsers, selectInactiveUsers } from "../../../usersSlice";
import "./users.css";
import connectedLogo from "../../../assets/connected.svg";
import incompleteLogo from "../../../assets/iconnotComplete.svg";
import inactiveLogo from "../../../assets/off.svg";
import downloadLogo from "../../../assets/download.svg";
import userLogo from "../../../assets/allUser.svg";
import { RootState, AppDispatch } from "../../../store";
import { exportToCsv } from "../../../service/csvExporter";

interface UserData {
    id: number;
    active: boolean;
    address?: string;
    agencyName?: string;
    email: string;
    phoneNumber?: string;
    linkedin?: string;
    insta?: string;
    gmb?: string;
    fb?: string;
}

function Users() {
    const dispatch = useDispatch<AppDispatch>();
    const fullyConnectedUsers = useSelector(selectFullyConnectedUsers);
    const partiallyConnectedUsers = useSelector(selectPartiallyConnectedUsers);
    const inactiveUsers = useSelector(selectInactiveUsers);
    const connected = useSelector((state: RootState) => state.users.connected);
    const incomplete = useSelector((state: RootState) => state.users.incomplete);
    const inactive = useSelector((state: RootState) => state.users.inactive);
    const [loading, setLoading] = useState(false);
    const headers = [
        "agencyName",
        "email",
        "fb",
        "insta",
        "linkedin",
        "gmb",
    ];

    useEffect(() => {
        dispatch(fetchUsers(true));
    }, [dispatch]);

    const handleUsersExportClick = (usersData: UserData[], filename: string) => {
        setLoading(true);
        try {
            exportToCsv(filename, headers, usersData);
        } catch (error) {
            console.error("Erreur lors de la préparation des données pour le CSV ", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="publication-smallBox">
            <div className="box-title">
                <img src={userLogo} alt="logo user"/>
                <div className="title">
                    Comptes utilisateurs
                </div>
            </div>
            <div className="groups">
                <div className="group-connected">
                    <div className="publication-stats">
                        <img src={connectedLogo} alt="connected logo"/>
                        <span className='publication-stats-title'>Connectés</span>
                        <span className="publication-stats-number">{connected}</span>
                        <button className="btn-download"
                                onClick={() => handleUsersExportClick(fullyConnectedUsers, 'fully_connected_users.csv')} disabled={loading}>
                            <img src={downloadLogo} alt="download logo"/>
                        </button>
                    </div>
                </div>
                <div className="group-incomplete">
                    <div className="publication-stats">
                        <img src={incompleteLogo} alt="incomplete logo"/>
                        <span className='publication-stats-title'>Incomplet</span>
                        <span className="publication-stats-number">{incomplete}</span>
                        <button className="btn-download"
                                onClick={() => handleUsersExportClick(partiallyConnectedUsers, 'partially_connected_users.csv')} disabled={loading}>
                            <img src={downloadLogo} alt="download logo"/>
                        </button>
                    </div>
                </div>
                <div className="group-inactive">
                    <div className="publication-stats">
                        <img src={inactiveLogo} alt="inactive logo"/>
                        <span className='publication-stats-title'>Inactif</span>
                        <span className="publication-stats-number">{inactive}</span>
                        <button className="btn-download"
                                onClick={() => handleUsersExportClick(inactiveUsers, 'inactive_users.csv')} disabled={loading}>
                            <img src={downloadLogo} alt="download logo"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Users;