import React from 'react';

const Pagination = ( params:any ) => {
    return (
        <div className="pagination">
            <button className="pagination-previous"
                    onClick={params.previous}
                    disabled={params.currentPage === 1}>
            </button>
            <span className="pagination-current">{params.currentPage} / {params.totalPage}</span>
            <button className="pagination-next"
                    onClick={params.next}
                    disabled={params.currentPage === params.totalPage}>
            </button>
        </div>
    );
}

export default Pagination;
