import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {RootState} from "./store";

interface UserData {
    id: number;
    active: boolean;
    address?: string;
    agencyName?: string;
    email: string;
    phoneNumber?: string;
    linkedin?: string;
    insta?: string;
    gmb?: string;
    fb?: string;
    request?: number;
    quota?: number;
}

interface UsersState {
    users: UserData[];
    connected: number;
    incomplete: number;
    inactive: number;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: UsersState = {
    users: [],
    connected: 0,
    incomplete: 0,
    inactive: 0,
    status: 'idle',
    error: null
};

const url = "/api/users?pagination=false";
const token = localStorage.getItem('userToken');

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async (isActive: boolean | null = null, { rejectWithValue }) => {
        try {
            let queryUrl = url;
            if (isActive !== undefined) {
                queryUrl += `&isActive=${isActive}`;
            }
            const response = await axios.get(queryUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            return await response.data['hydra:member'];
        } catch (error) {
            console.error("Error during fetch:", error);
            return rejectWithValue("Erreur lors de la récupération des données");
        }
    }
);

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<UserData[]>) => {
                state.status = 'succeeded';
                if (Array.isArray(action.payload)) {
                    state.users = action.payload;
                    let fullConnected = 0;
                    let incompleteConnexion = 0;
                    let inactiveConnexion = 0;
                    action.payload.forEach((user) => {
                        if (user.insta && user.fb && user.gmb && user.linkedin) {
                            fullConnected++;
                        }
                        else if (user.insta || user.fb || user.gmb || user.linkedin) {
                            incompleteConnexion++;
                        }
                        else {
                            inactiveConnexion++;
                        }
                    });
                    state.connected = fullConnected;
                    state.incomplete = incompleteConnexion;
                    state.inactive = inactiveConnexion;
                } else {
                    state.users = [];
                    state.connected = 0;
                    state.incomplete = 0;
                    state.inactive = 0;
                }

            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const selectAllUsers = (state: RootState) => state.users.users;
export const selectFullyConnectedUsers = (state: RootState) => state.users.users.filter(user => user.insta && user.fb && user.gmb && user.linkedin);
export const selectPartiallyConnectedUsers = (state: RootState) => state.users.users.filter(user => (user.insta || user.fb || user.gmb || user.linkedin) && !(user.insta && user.fb && user.gmb && user.linkedin));
export const selectInactiveUsers = (state: RootState) => state.users.users.filter(user => !user.insta && !user.fb && !user.gmb && !user.linkedin);

export default usersSlice.reducer;