export function exportToCsv(filename: string, headers: string[], rows: any[]): void {
    const networkFields = ['insta', 'fb', 'gmb', 'linkedin'];
    const csvHeader = headers.join(",") + "\n";

    const csvRows = rows.map(row => {
        return headers.map(header => {
            let value = networkFields.includes(header) ? (row[header] ? '1' : '0') : row[header];
            return `"${value ?? ''}"`;
        }).join(",");
    }).join("\n");
    const csvContent = csvHeader + csvRows;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const urlMedia = URL.createObjectURL(blob);

    link.setAttribute("href", urlMedia);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export async function handleExportClick(
    headers: string[],
    fetchFunction: (id?: number) => Promise<any[]>,
    filename: string,
    beforeExport: () => void,
    afterExport: () => void,
    id?: number
): Promise<void> {
    beforeExport();
    try {
        const data = await fetchFunction(id);
        exportToCsv(filename, headers, data);
    } catch (error) {
        console.error("Erreur lors de la récupération des données pour le CSV ", error);
    } finally {
        afterExport();
    }
}