import React, { useEffect, useState } from "react";
import { useDispatch, Provider } from "react-redux";
import { logout, setUser } from "./userSlice";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import ErrorPage from "./ErrorPage";
import Login from "./login/Login";
import Dashboard from "./dashboard/Dashboard";
import { store } from "./store";
import "./index.css";
import Users from "./users/Users";
import Publications from "./publications/Publications";
import PublicationForm from "./publications/form/PublicationForm";
import ArchivedPublications from "./publications/archived/ArchivedPublications";
import "./service/axiosConfig";
import PrivateRoute from "./admin/PrivateRoute";
import UserRoute from "./UserRoute";

const AppWithUserPersistence = () => {
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const initializeAuth = async () => {
            const userEmail = localStorage.getItem("userEmail");
            const userId = Number(localStorage.getItem("userId"));
            const userRoles = localStorage.getItem("roles");
            const token = localStorage.getItem('userToken');
            const isAdmin = userRoles?.includes("ROLE_ADMIN");
            const quota = Number(localStorage.getItem("quota"));

            if (userEmail && userId && token) {
                dispatch(setUser({
                    isAuthenticated: true,
                    isAdmin: !!isAdmin,
                    id: userId,
                    name: userEmail,
                    token: token,
                    quota: quota,
                }));
            } else {
                dispatch(logout());
            }
            setInitialized(true);
        };

        initializeAuth();
    }, [dispatch]);

    if (!initialized) {
        return <div>Loading...</div>;  // Or some other kind of loading indicator
    }

    const router = createBrowserRouter([
        {
            path: "/",
            element: <App />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "login",
                    element: <Login />
                },
                {
                    path: "/",
                    element: <UserRoute />,
                    children: [
                        {
                            path: "dashboard",
                            element: <Dashboard />
                        },
                        {
                            path: "users",
                            element: <Users />
                        },
                        {
                            path: "publications",
                            element: <Publications />
                        },
                        {
                            path: "add_publication",
                            element: <PublicationForm />
                        },
                        {
                            path: `edit_publication/:publicationId`,
                            element: <PublicationForm />
                        },
                        {
                            path: "archived_publications",
                            element: <ArchivedPublications />
                        },
                    ]
                },
                {
                    path: "register",
                    element: <PrivateRoute />
                }
            ]
        },
    ]);

    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <AppWithUserPersistence />
        </Provider>
    </React.StrictMode>
);
