import React from "react";
import { logout } from "../userSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function Logout() {
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout());
        localStorage.clear();
    }

    return <Link to="/login" onClick={handleLogout}
                 className="text-wrapper"
                >Déconnexion</Link>
}

export default Logout;
