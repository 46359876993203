import React, {useEffect} from 'react';
import './App.css';
import {Outlet, useNavigate} from "react-router-dom";
import Header from "./header/Header";
import {Provider, useSelector} from "react-redux";
import {RootState, store} from "./store";

function App() {
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

    useEffect(() => {
        if (isAuthenticated !== undefined) {
            navigate(isAuthenticated ? "/dashboard" : "/login");
        }
    }, [navigate, isAuthenticated]);

    return (
        <Provider store={store}>
            <div className="app">
                <Header />
                <Outlet />
            </div>
        </Provider>
  );
}

export default App;
