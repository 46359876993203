import React, { useEffect, useState } from 'react';
import "./lastPublications.css";
import { Link } from "react-router-dom";
import axios from "axios";
import lastPubliIcon from '../../../assets/lastPubli.svg';
import Spinner from "../../../spinner/Spinner";
import mediaUrl from "../../../service/mediaUrl";

function LastPublications() {
    const [numberOfPublications] = useState(3);
    const [publications, setPublications] = useState([]);
    let url = "/api/publications?status=2&order[createdAt]=desc&itemsPerPage=" + numberOfPublications;

    function loadPublications(url:string) {
        try {
            axios.get(url).then((data) => {
                setPublications(data?.data['hydra:member']);
            });
        } catch (error) {
            console.error(
                'Erreur lors de la récupération des dernières publications :',
                error
            );
        }
    }

    useEffect(() => {
        loadPublications(url);
    }, [url]);

    return (
        <div className="box">
            <div className="box-title">
                <img src={lastPubliIcon} alt="last publication icon"/>
                <div className="title">Dernières publications</div>
            </div>
            <Spinner loading={publications.length === 0}/>
            {publications.map((publi: any, index:any) => {
                const url = mediaUrl(publi);
                return <div key={index} className="publi-card">
                          <img src={url} alt="illustration" />
                          <div className="body">
                             <div className="body-title">{publi?.title}</div>
                             <div className="body-text">{publi?.content}</div>
                          </div>
                        </div>
            })}
            {publications.length > 0 && (
                <Link className="btn btn-block btn-lastpub align-center" to={"/publications"}>
                    Voir les publications
                </Link>
            )}
        </div>
    );
}

export default LastPublications;