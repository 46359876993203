import React, {SyntheticEvent, useState} from "react";
import axios from "axios";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../userSlice";

const Login = () => {
    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });
    const [errorMessage, setErrorMessage] = useState('');

    const onChange = (e: any) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        } )
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const url = '/api/login_check';

    const submitHandler = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            let response = await axios.post(url, credentials, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            localStorage.setItem("userToken", response.data.token );
            let userUrl = "/api/user_admins?email=" + credentials.username;
            let userInfo = await axios.get(userUrl);
            const user = userInfo.data['hydra:member'][0];
            const isAdmin = user.roles.includes("ROLE_ADMIN");
            localStorage.setItem("userEmail", user.email );
            localStorage.setItem("userId", user.id );
            localStorage.setItem('roles', user.roles);
            localStorage.setItem('isAdmin', isAdmin);
            localStorage.setItem('quota', user.quotaIA);
            dispatch(setUser({
                isAuthenticated: true,
                isAdmin: isAdmin,
                id: user.id,
                name: user.email,
                token: response.data.token,
                quota: user.quotaIA,
            }));
            navigate('/dashboard');
        } catch (error: any) {
            if (error.response?.status === 401) {
                setErrorMessage("Nom d'utilisateur ou mot de passe incorrect.");
            }
            console.error(error);
            setErrorMessage("Une erreur s'est produite, veuillez réessayer.");
        }
    }

    return (
        <>
            <form onSubmit={submitHandler} className="form-group">
                <h1>Se connecter</h1>
                <div className="group">
                    <label htmlFor="login">Identifiant</label>
                    <input type="email" name="username" className="form-control"
                           placeholder=" "
                           value={credentials.username}
                           onChange={onChange}/>
                </div>
                <div className="group">
                    <label htmlFor="password">Mot de passe</label>
                    <input type="password" name="password" className="form-control"
                           placeholder=" "
                           value={credentials.password}
                           onChange={onChange}/>
                </div>
                {errorMessage && (
                    <p className="warning-login">{errorMessage}</p>
                )}
                <div className="group">
                    <button className="btn btn-block">Connexion</button>
                </div>
            </form>
        </>
    );
};

export default Login;