import React, {useRef} from 'react';
import './UploadFile.css';
import uploadLogo from "../assets/upload.svg";
import video from "../assets/video.png";

interface UploadFileProps {
    handleMediaChange: (e: React.ChangeEvent<HTMLInputElement>, networkKey?: string) => void;
    resetMedia: (networkKey?: string) => void;
    disabled: boolean
    media: string;
    extension: string;
    networkKey?: string;
}

const UploadFile: React.FC<UploadFileProps> = ({ handleMediaChange, extension, resetMedia, disabled,media, networkKey }) => {
    const inputFile = useRef<HTMLInputElement>(null);

    return (
        <>
            <div className="upload-file">
                <img src={uploadLogo} width="32" height="32" alt="upload-logo"/>
                <span className="upload-file-title">Déposer vos fichiers ici</span>
                <span className="upload-file-subtile">ou</span>
                <span className="upload-file-btn">Ajouter un fichier</span>
                <span className="upload-file-accepted">{networkKey !== 'googleMyBusiness'
                    ? "10Mo max, .jpg, .mp4 acceptés"
                    : "10Mo max, .jpg acceptés"}</span>
                <input
                    ref={inputFile}
                    name="media"
                    id={networkKey || 'basePublication'}
                    type="file"
                    accept={networkKey !== 'googleMyBusiness' ? "image/jpeg, image/jpg, video/mp4" : "image/jpeg, image/jpg"}
                    onChange={(e) => handleMediaChange(e, networkKey)}
                    onClick={(e) => e.stopPropagation()}
                    multiple={false}
                    disabled={disabled}
                />
                {media && (
                    <div className='media-loaded'>
                    <button className="btn btn-close-media" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        resetMedia(networkKey)
                    }}></button>
                    <img className="media-loaded-picture"
                            src={extension === 'mp4' ? video : media}
                            alt={media}
                    />
                </div>)}
            </div>
        </>
);
}

export default UploadFile;
