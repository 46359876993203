import React , {useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import Navbar from "../navbar/Navbar";

interface RegisterFormData {
    email: string;
    password: string;
}
const Register: React.FC =  () => {
    const [formData, setFormData] =
        useState<RegisterFormData>({
            email: '',
            password: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await axios.post('/api/register', formData);
        } catch (error) {
            console.error('Error registering user:', error);
        }
    };

    return (
        <>
            <div className="container">
                <div className="col-4">
                    <Navbar />
                </div>
                <div className="col-8">
                    <form onSubmit={handleSubmit} className="form-group">
                        <h1>Enregistrement</h1>
                        <div className="group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                className="form-control"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="group">
                            <label htmlFor="password">Mot de passe:</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="group">
                            <button type="submit" className="btn btn-block">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Register;