import axios, {AxiosError} from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_NEWS_FACTORY_API_URL;

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('userToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(
    response => response,
    (error: AxiosError) =>  {
        if (error.config && error.config.url === '/api/login') {
            if (error.response?.status === 401) {
                return Promise.reject(error);
            }
        }
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userToken');
            window.location.href = '/login';
        }

        return Promise.reject(error)
    }
);
