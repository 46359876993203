import React, {ReactNode} from 'react';
import "./filter.css";

interface FilterProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}
const Filter: React.FC<FilterProps> = ({ isOpen, onClose, children}) => {
    if (!isOpen) return null;

    return (
        <div className="filter">
            <div className="filter-content">
                <button className='btn-close'
                        onClick={onClose}></button>
                {children}
            </div>
        </div>
    );
};

export default Filter;