import React, {useState, useMemo, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from "../rootReducer";
import { AgGridReact } from 'ag-grid-react';
import { Link } from "react-router-dom";
import { ColDef } from 'ag-grid-community';
import { exportToCsv, handleExportClick } from "../service/csvExporter";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './publication.css';
import axios from "axios";
import Navbar from "../navbar/Navbar";
import logoFb from "../assets/logofb.svg";
import logoInsta from "../assets/logoinsta.svg";
import logoGmb from "../assets/logogmb.svg";
import logoIn from "../assets/logoin.svg";
import logoInProgressColored from "../assets/icon-inprogress-colored.svg";
import logoFinishedColored  from "../assets/icon-finished-colored.svg";
import logoFbColored from "../assets/icon-facebook-colored.svg";
import logoInstaColored  from "../assets/icon-instagram-colored.svg";
import logoGmbColored  from "../assets/icon-google-colored.svg";
import logoInColored  from "../assets/icon-linkedin-colored.svg";
import archive from "../assets/archive.svg";
import editOn from "../assets/editOn.svg";
import editOff from "../assets/editOff.svg";
import stat from "../assets/stat.svg";
import Pagination from "../pagination/Pagination";
import Spinner from "../spinner/Spinner";
import mediaUrl from "../service/mediaUrl";
import Filter from "../filter/filter";
import DateRangeComponent from "../daterangepicker/DateRangeComponent";
import format from "date-fns/format";

interface Publication {
    media: string;
    extension: string;
    title: string;
    content: string;
    status: string;
    createdAt: string;
    publicationDate: string;
    id: number;
}

const statusCellRenderer = (params: any) => {
    return (
        <>
            <div className={ 'ag-cell-container' }>
                <span className={ 'ag-cell-status status-' + params.data.status }>
                    {params.data.status === 1 ? "EN COURS" : "TERMINÉE"}
                </span>
            </div>
         </>
    );
}

const socialMediaCellRenderer = (params: any) => {
    const isActive = (network: string) => params.data.social.includes(network) ? 'active' : 'inactive';

    return (
        <>
            <div className={ 'ag-cell-container' }>
                <img src={logoFb} alt="Facebook" className={`icon ${isActive('facebook')}`} />
                <img src={logoInsta} alt="Instagram" className={`icon ${isActive('instagram')}`} />
                <img src={logoIn} alt="LinkedIn" className={`icon ${isActive('linkedin')}`} />
                <img src={logoGmb} alt="Google My Business" className={`icon ${isActive('googleMyBusiness')}`} />
            </div>
        </>
    );
}

const publicationCard = (params: any) => {
    return (
        <>
            <div className={'card'}>
                <img src={ mediaUrl(params.data) }
                     className={'img-card'} alt='illustration'/>
                <div className={'card-texts'}>
                    <div className={'card-text'}>
                        <span className={"card-text-title"}>{params.data.title}</span>
                    </div>
                    <div className={'card-text'}>
                        <span className={"card-text-content"}>{params.data.content}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

function Publications() {
    const userEmail = useSelector((state: RootState) => state.user.name);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [publications, setPublications] = useState<Publication[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchStatus, setSearchStatus] = useState<string>('');
    const [searchNetwork, setSearchNetwork] =
        useState<Record<string, boolean>>({
            facebook: false,
            instagram: false,
            linkedin: false,
            googleMyBusiness: false,
    });
    const [isSearching, setIsSearching] =
        useState<Record<string, boolean>>({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDateOpen, setDateOpen] = useState(false);
    const [dateStart, setDateStart]= useState("");
    const [dateEnd, setDateEnd]= useState("");
    const [dateOrder, setDateOrder] =
        useState<Record<string, string>>({
            field: '',
            value: '',
    });
    const isFetching = useRef(false);

    let itemsPerPage = 10;
    const url = `/api/publications`;

    async function fecthAllPublications(): Promise<Publication[]> {
        setLoading(true);
        let allPublications: Publication[] = [];
        let itemsPerPage = 500;
        let fetchPage = 1;
        let hasMore = true;

        while (hasMore) {
            const response = await axios.get(url + `?itemsPerPage=${itemsPerPage}&page=${currentPage}&order%5BcreatedAt%5D=asc`);

            allPublications = allPublications.concat(response.data["hydra:member"]);

            const allItems = response.data['hydra:totalItems'];
            hasMore = fetchPage * itemsPerPage < allItems;
            fetchPage++;
            setCurrentPage(fetchPage);
        }

        setLoading(false);

        return allPublications;
    }

    async function handleSearchText(text: string) {
        setSearchText(text);
        if (text.length >= 5) {
            setIsSearching({'text': true});
        } else {
            setIsSearching({});
        }
    }

    async function handleSearchStatus(status: string) {
        setSearchStatus(status);
        if (status === "1" || status === "2") {
            setIsSearching({'status': true});
        } else {
            setIsSearching({});
        }
    }

    const handleNetworkChange = (network: string) => {
        setSearchNetwork(prev => ({
            ...prev,
            [network]: !prev[network]
        }));
        if (network) {
            setIsSearching({'network': true});
        } else {
            setIsSearching({});
        }
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    }

    const handleOpenDate = () => {
        setDateOpen(true);
    }

    const handleStat = async (id: number) => {
        try {
            const response = await axios.get(`/api/publication_stat/${id}`);

            if (response.data.stat) {
                const filename = `publication_stats${id}.csv`;
                const headers = ["publicationId", "title", "network", "status", "message", "agencyName"];
                exportToCsv(filename, headers, response.data.stat);
            }

        } catch (error) {
            console.error("Error when fetching publication stat : ", error);
        }
    }

    const handleArchive = async (id: number) => {
        const date = new Date();
        try {
            const data = {
                status: 0,
                updatedBy: userEmail,
                updatedAt: format(date, 'yyyy-MM-dd'),
            }
            const response = await axios.patch(`/api/publications/${id}`, data, {
                headers: {
                    "Content-Type": "application/merge-patch+json"
                }
            });

            if (response.status === 200) {
                setPublications((prevPublications) =>
                    prevPublications.filter((publication) => publication.id !== id)
                );
            }
        } catch (error) {
            console.error(`Error when archive publication ${id} : `, error);
        }
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    }

    const handleCloseDate = () => {
        setDateOpen(false);
    }

    const handleSearchDate = (value: string) => {
        setIsSearching({"date": true});
        if (value === "CASC" || value === "CDESC" || value === "PASC" || value === "PDESC") {
            switch (value) {
                case 'CASC':
                    setDateOrder({
                        field: 'createdAt',
                        value: 'ASC'
                    });
                    break;
                case 'CDESC':
                    setDateOrder({
                        field: 'createdAt',
                        value: 'DESC'
                    });
                    break;
                case 'PASC':
                    setDateOrder({
                        field: 'publicationDate',
                        value: 'ASC'
                    });
                    break;
                case 'PDESC':
                    setDateOrder({
                        field: 'publicationDate',
                        value: 'ASC'
                    });
                    break;
            }
        } else {
            setIsSearching({});
        }
    }

    const handleDateChange = (selection: any) => {
        if (selection) {
            setDateStart(format(selection.startDate, "yyyy-MM-dd"));
            setDateEnd(format(selection.endDate, 'yyyy-MM-dd'));
            setIsSearching({'date': true});
        } else {
            setIsSearching({});
        }

    }

    const handleResetFilters = () => {
        handleSearchStatus('');
        handleNetworkChange('');
        setModalOpen(false);
    }

    const handleResetDateFilters = () => {
        handleDateChange('');
        handleSearchDate('');
        setDateOpen(false);
    }

    const headers = ["id", "title", "content", "media", "link", "social", "status", "createdAt", "date"];

    const onExportClick = () => {
        handleExportClick(
            headers,
            fecthAllPublications,
            "publications.csv",
            () => setLoading(true),
            () => setLoading(false)
        );
    }

    useEffect(() => {
        if (isFetching.current) {
            return;
        }
        const fetchData = async () => {
            isFetching.current = true;
            try {
                setLoading(true);
                let publications: Publication[] = [];
                let searchUrl = "";
                const baseStatusUrl = `status[]=1&status[]=2&page=${currentPage}&itemsPerPage=${itemsPerPage}`;
                const fetchUrl = `?${baseStatusUrl}&order%5BcreatedAt%5D=desc`;
                const filters = Object.entries(searchNetwork)
                                                .filter(([_, value]) => value)
                                                .map(([key]) => key);
                const socialParams = filters.map(network => `social[]=${network}`).join('&');
                for (const [key,] of Object.entries(isSearching)) {
                    switch (key) {
                        case "text":
                            searchUrl = `?${baseStatusUrl}&title=${searchText}`;
                            break;
                        case "status":
                            searchUrl = `?status%5B%5D=${searchStatus}`;
                            break;
                        case "network":
                            if (searchStatus) {
                                searchUrl = `?status[]=${searchStatus}&${socialParams}`;
                            } else {
                                searchUrl = `?${baseStatusUrl}&${socialParams}`;
                            }
                            break;
                        case "date":
                            if (dateOrder.field && dateOrder.value && dateStart && dateEnd) {
                                searchUrl = `?${baseStatusUrl}&${dateOrder.field}[after]=${dateStart}&${dateOrder.field}[before]=${dateEnd}&order[${dateOrder.field}]=${dateOrder.value}`
                            } else if (dateOrder.field && dateOrder.value){
                                searchUrl = `?${baseStatusUrl}&order[${dateOrder.field}]=${dateOrder.value}`
                            } else {
                                searchUrl = `?${baseStatusUrl}&createdAt[before]=${dateEnd}&createdAt[after]=${dateStart}`
                            }
                    }
                }

                const response = await axios.get(
                    url + (searchUrl ? searchUrl : fetchUrl));
                publications = response.data["hydra:member"];
                const totalItems = response.data['hydra:totalItems'];
                setTotalPage(Math.ceil(totalItems / itemsPerPage));
                setPublications(publications);
            } catch (error) {
                console.error(
                    'Erreur lors de la récupération des publications :',
                    error
                );
            } finally {
                isFetching.current = false;
                setLoading(false);
            }
        }
        fetchData();
    }, [url, currentPage, itemsPerPage, isSearching, searchText, searchStatus, searchNetwork, dateOrder.field, dateOrder.value, dateStart, dateEnd]);

    function dateGenerator(dateAsString: any) {
        let newDate = new Date(dateAsString);
        let day_month = newDate.toLocaleString('fr-FR', { day: 'numeric',  month: 'short' });
        let year = newDate.toLocaleString('fr-FR', { year: 'numeric' });
        return (
            <>
                <div className='ag-cell-date'>
                    <span className='ag-cell-date-day'>{day_month}</span>
                    <span className='ag-cell-date-year'>{year}</span>
                </div>
            </>
        )
    }

    function dateFormatter(params: any) {
        return dateGenerator(params.data.publicationDate);
    }

    function createdAtFormatter(params: any) {
        return dateGenerator(params.data.createdAt);
    }

    function actionButtons(params: any) {
        let id = params.data.id;
        let status = params.data.status;
        return (
            <>
                <div className='ag-cell-container'>
                    {
                        status === 2 ? (
                            <img src={editOff} className={'icon'} alt="edit icon"/>
                        ) : (
                            <Link to={`/edit_publication/${id}`}><img src={editOn} className={'icon'} alt="edit icon"/></Link>
                        )
                    }
                    {
                        status === 2 ? (
                            <button onClick={() => handleStat(id)} className={'icon-btn'}><img src={stat} className={'icon'} alt="stat icon"/></button>
                        ) : (
                            <img src={stat} className={'icon'} alt="stat icon" style={{opacity: 0.5}}/>
                        )
                    }

                    <button onClick={() => handleArchive(id)}><img src={archive} className={'icon'} alt="archive icon"/></button>
                </div>
            </>
        )
    }

    const defaultColDef = useMemo(() => {
        return {
            resizable: false,
            sortable: false,
            suppressMovable: true,
        }
    }, []);

    const columnDefs: ColDef<Publication>[] = [
        { headerName: 'Publication',
            cellRenderer: publicationCard,
            autoHeight: true,
            flex: 3
        },
        { headerName: 'Status',
            cellRenderer: statusCellRenderer,
            flex: 1
        },
        { headerName: 'Créée Le',
            field: 'createdAt',
            sortable: true,
            cellRenderer: createdAtFormatter,
            flex: 1,
        },
        { headerName: 'Publiée Le',
            field: 'publicationDate',
            sortable: true,
            cellRenderer: dateFormatter,
            flex: 1
        },
        { headerName: "Réseaux Sociaux",
            cellRenderer: socialMediaCellRenderer,
            flex: 1
        },
        { headerName: "Action",
            cellRenderer: actionButtons,
            flex: 1
        },
    ];

    const containerStyle = useMemo(() =>
        ({ width: "100%", height: "-webkit-fill-available", fontFamily: 'Montserrat'}), []);

    return (
        <>
            <div className="container">
                <div className="col-4">
                    <Navbar />
                </div>
                <div className="col-8">
                    <div className="right-panel">
                        <div className="right-panel-container">
                            <div className="title-bar">
                                <div className="main-title">
                                    <h1>Mes publications</h1>
                                    <p>Retrouvez l'ensemble de vos publications sur cette page</p>
                                </div>
                                <div className="main-buttons">
                                    <Link className="btn-login btn-block btn-archive"
                                          to={'/archived_publications'}>
                                        Mes publications archivées
                                    </Link>
                                    <Link className="btn btn-block add-circle"
                                          to={'/add_publication'}>
                                        Créer une publication
                                    </Link>
                                </div>
                            </div>
                            <div className={'search-bar'}>
                                <input type="text"
                                    className="search-input"
                                    placeholder="Rechercher une publication..."
                                    value={searchText}
                                    onChange={(e) => handleSearchText(e.target.value)}
                                />
                                <div className={'search-buttons'}>
                                    <button className='csv-export' onClick={onExportClick} disabled={loading}>Exporter</button>
                                    <button className='csv-sort' onClick={handleOpenModal}>Trier par</button>
                                    <button className='csv-date' onClick={handleOpenDate}>Date</button>
                                </div>
                                <Filter isOpen={isModalOpen}
                                    onClose={handleCloseModal} >
                                    <div className="filter-title">
                                        <span className="filter-title-sort">Trier par</span>
                                        <Link className="filter-title-reset"
                                              onClick={() => handleResetFilters()}
                                              to="">Réinitialiser</Link>
                                    </div>
                                    <div className="filter-status">
                                        <span className="filter-status-title">Status</span>
                                        <div className="filter-input">
                                            <input
                                                type="radio"
                                                value={"1"}
                                                name={"status"}
                                                onChange={(e) => handleSearchStatus(e.target.value)}/>
                                            <label htmlFor="en_cours">
                                                <img src={logoInProgressColored} alt="en cours" className={"filter-icon"}/>
                                                Status en cours</label>
                                        </div>
                                        <div className="filter-input">
                                            <input
                                                type="radio"
                                                value={"2"}
                                                name={"status"}
                                                onChange={(e) => handleSearchStatus(e.target.value)}/>
                                            <label htmlFor="terminee">
                                                <img src={logoFinishedColored} alt="terminé" className={"filter-icon"}/>
                                                Status terminée</label>
                                        </div>
                                    </div>
                                    <div className="filter-networks">
                                        <span className="filter-networks-title">Réseaux sociaux</span>
                                        <div className="filter-input">
                                            <input type="checkbox"
                                                   id={"facebook"}
                                                   value={"facebook"}
                                                   checked={searchNetwork.facebook}
                                                   onChange={() => handleNetworkChange('facebook')}
                                            />
                                            <label htmlFor="facebook">
                                                <img src={logoFbColored} alt="Facebook" className={"filter-icon"}/>
                                                Facebook
                                            </label>
                                        </div>
                                        <div className="filter-input">
                                            <input type="checkbox"
                                                   id={"instagram"}
                                                   value={"instagram"}
                                                   checked={searchNetwork.instagram}
                                                   onChange={() => handleNetworkChange('instagram')}
                                            />
                                            <label htmlFor="instagram">
                                                <img src={logoInstaColored} alt="Instagram" className={"filter-icon"}/>
                                                Instagram
                                            </label>
                                        </div>
                                        <div className="filter-input">
                                            <input type="checkbox"
                                                   id={"linkedin"}
                                                   value={"linkedin"}
                                                   checked={searchNetwork.linkedin}
                                                   onChange={() => handleNetworkChange('linkedin')}
                                            />
                                            <label htmlFor="linkedin">
                                                <img src={logoInColored} alt="linkedin" className={"filter-icon"}/>
                                                Linkedin
                                            </label>
                                        </div>
                                        <div className="filter-input">
                                            <input type="checkbox"
                                                   id={"googleMyBusiness"}
                                                   value={"googleMyBusiness"}
                                                   checked={searchNetwork.googleMyBusiness}
                                                   onChange={() => handleNetworkChange('googleMyBusiness')}
                                            />
                                            <label htmlFor="gmb">
                                                <img src={logoGmbColored} alt="google my business" className={"filter-icon"}/>
                                                Google My Business
                                            </label>
                                        </div>
                                    </div>
                                </Filter>
                                <Filter isOpen={isDateOpen}
                                    onClose={handleCloseDate} >
                                    <Link className="filter-title-reset-date"
                                          onClick={() => handleResetDateFilters()}
                                          to="">Réinitialiser</Link>
                                    <span>Date</span>
                                    <div>
                                        <p>Création / Publication</p>
                                        <div className="filter-input">
                                            <input
                                                type="radio"
                                                value={"CASC"}
                                                name={"dateOrder"}
                                                onChange={(e) => handleSearchDate(e.target.value)}/>
                                            <label htmlFor="Date de création croissante">
                                                Date de création croissante</label>
                                        </div>
                                        <div className="filter-input">
                                            <input
                                                type="radio"
                                                value={"CDESC"}
                                                name={"dateOrder"}
                                                onChange={(e) => handleSearchDate(e.target.value)}/>
                                            <label htmlFor="terminee">
                                                Date de création décroissante</label>
                                        </div>
                                        <div className="filter-input">
                                            <input
                                                type="radio"
                                                value={"PASC"}
                                                name={"dateOrder"}
                                                onChange={(e) => handleSearchDate(e.target.value)}/>
                                            <label htmlFor="Date de création croissante">
                                                Date de publication croissante</label>
                                        </div>
                                        <div className="filter-input">
                                            <input
                                                type="radio"
                                                value={"PDESC"}
                                                name={"dateOrder"}
                                                onChange={(e) => handleSearchDate(e.target.value)}/>
                                            <label htmlFor="terminee">
                                                Date de publication décroissante</label>
                                        </div>
                                    </div>
                                    <div className={"date-picker"}>
                                        <p>Par période</p>
                                        <div className={"input-date-picker"}>
                                            <Link className="filter-title-reset-date"
                                                  onClick={() => handleResetDateFilters()}
                                                  to="">Réinitialiser</Link>
                                            <DateRangeComponent onDateChange={handleDateChange}/>
                                        </div>

                                    </div>
                                </Filter>
                            </div>
                            <div style={containerStyle}>
                                <div className="ag-theme-alpine"
                                     style={containerStyle}>
                                    <Spinner loading={loading}/>
                                    <AgGridReact
                                        rowData={publications}
                                        defaultColDef={defaultColDef}
                                        columnDefs={columnDefs}
                                        domLayout='autoHeight'
                                        pagination={false}
                                        overlayNoRowsTemplate={'Pas de données enregistrées'}
                                        suppressNoRowsOverlay={!!publications}
                                    />
                                </div>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    previous={() => setCurrentPage(p => p - 1)}
                                    next={() => setCurrentPage(p => p + 1)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Publications;
