interface Errors {
    [key: string]: string;
}

interface ErrorResult {
    error?: string;
}

export function inputError(errors: Errors, name: string): ErrorResult {
    if (errors[name]) {
        return { error: errors[name] };
    }

    return {};
}

export const isFormInvalid = (err: Errors) => {
    return Object.keys(err).length > 0;
}