import React from 'react';
import './Spinner.css';

const Spinner = ( params:any ) => {
    return (
        params.loading && (
            <div className="spinner">
                <div></div>
            </div>
        )
    );
}

export default Spinner;
