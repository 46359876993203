import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "./store";

const UserRoute = () => {
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
}

export default UserRoute;