import React, {ReactNode} from 'react';
import "./modal.css";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    className?: string;
}
const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, className}) => {
    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className={`modal-content ${className}`}>
                <button className='btn-close'
                        onClick={onClose}></button>
                {children}
            </div>
        </div>
    );
};

export default Modal;