import s3Object from "./s3getObject";
import video from "../assets/video.png";
import lien from "../assets/lien.png";
import imagenondispo from "../assets/imagenondispo.png";

function mediaUrl(params: any) {
    if (params.media && params.extension && params.extension === 'jpg' && !params.link) {
        const filename = params.media + '.' + params.extension;
        return s3Object(filename);
    } else if (params.media && params.extension && params.extension === 'mp4') {
        return video;
    } else if (params.link) {
        return lien;
    } else {
        return imagenondispo;
    }
}

export default mediaUrl;