import React from "react";
import "./navbar.css";
import {Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import homeIcon from "../assets/home.svg";
import publiIcon from "../assets/iconpubli.svg";
import userIcon from "../assets/iconuser.svg";
import logoutIcon from "../assets/iconlogout.svg";
import Logout from "../logout/Logout"

function Navbar() {
    const location = useLocation();
    const path = location.pathname;
    const unselectedColor = 'brightness(50%) grayscale(100%)';
    const selectedColor ='var(--primary-color)';
    const isAdmin = useSelector(
        (state: RootState) => state.user.isAdmin
    );

    return (
        <div className="navbar-container">
            <div className="menu">
                <div className="menu-link">
                    <img src={homeIcon}
                         alt="icon home"
                         style={{
                             filter: path==='/dashboard' ? '' : unselectedColor
                        }}/>
                    <Link to={'/dashboard'}
                          className="text-wrapper"
                          style={{
                              color: path==='/dashboard' ? selectedColor : ''
                          }}>Accueil</Link>
                </div>
                <div className="menu-link">
                    <img src={publiIcon}
                         alt="icon publications"
                         style={{
                             filter: path==='/publications' ? '' : unselectedColor
                         }}/>
                    <Link to={'/publications'}
                          className="text-wrapper"
                          style={{
                              color: path==='/publications' ? selectedColor : ''
                          }}>Mes publications</Link>
                </div>
                <div className="menu-link">
                    <img src={userIcon}
                         alt="icon user"
                         style={{
                             filter: path==='/users' ? '' : unselectedColor
                         }}/>
                    <Link to={'/users'}
                          className="text-wrapper"
                          style={{
                              color: path==='/users' ? selectedColor : ''
                          }}>Utilisateurs</Link>
                </div>
            </div>
            {isAdmin && (
                <div className="menu-link">
                    <img src={userIcon}
                         alt="icon user"
                         style={{
                             filter: path === '/register' ? '' : unselectedColor
                         }}/>
                    <Link to={'/register'}
                          className={"text-wrapper"}
                          style={{
                              color: path === '/register' ? selectedColor : ''
                          }}>Création de compte</Link>
                </div>
            )}
            <div className="sub-menu">
                <div className="menu-link">
                    <img src={logoutIcon} alt="icon logout"/>
                    <Logout/>
                </div>
            </div>
        </div>
    );
}

export default Navbar;