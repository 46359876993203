import moment from 'moment-timezone';

export const convertToDefaultTimezone = (serverDate: string, timezone: string): string => {
    const date = moment.tz(serverDate, timezone);
    return date.format('YYYY-MM-DD HH:mm:ss');
};

export const convertToISO8601WithTimezone = (dateString: string, timeString: string, timezone: string): string => {
    const datetime = `${dateString} ${timeString}`;
    return moment.tz(datetime, timezone).format(); // Conserve le décalage de fuseau horaire
};
