import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Register from "../register/Register";
import ErrorPage from "../ErrorPage";

const PrivateRoute = () => {
    const isAdmin = useSelector(
        (state: RootState) => state.user.isAdmin
    );
    return isAdmin ? <Register /> : <ErrorPage />;
}

export default PrivateRoute;