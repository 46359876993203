import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { fr } from "date-fns/locale";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRangeComponentProps {
    onDateChange: (selection: { startDate: Date, endDate: Date }) => void;
}

const DateRangeComponent: React.FC<DateRangeComponentProps> = ({ onDateChange }) => {
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const handleSelect = (ranges: any) => {
        setDateRange(ranges.selection);
        onDateChange(ranges.selection);
    };

    const defineRange =  {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        key: 'selection',
    };

    return (
        <div className={"picker"}>
            <DateRangePicker
                ranges={[defineRange]}
                onChange={handleSelect}
                locale={fr}
                rangeColors={["#BED000"]}
                color={"#000"}
            />
        </div>
    );
}

export default DateRangeComponent;