import React, {useEffect, useState, useCallback, useRef} from "react";
import "./statistics.css";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import publicationLogo from "../../../assets/allPubli.svg";
import Spinner from "../../../spinner/Spinner";

interface MonthlyStats {
    month: string;
    data: {
        facebook: number;
        instagram: number;
        linkedin: number;
        gmb: number;
        [key: string]: number;
    }
}

interface Connection {
    date: string;
    network: string;
}

function Statistics() {
    const chartDiv = useRef<HTMLDivElement>(null);
    const userData = useSelector((state: RootState) => state.users.users);
    const [monthlyStatsMap, setMonthlyStatsMap] = useState<Map<string, MonthlyStats>>(new Map());

    const aggregateConnection = useCallback((dateStr: string, network: string, statsMap: Map<string, MonthlyStats>) => {
        const date = new Date(dateStr);
        const now = new Date();

        if (date.getTime() > now.getTime()) {
            return;
        }
        const monthKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
        let currentMonthStats = statsMap.get(monthKey);

        if (!currentMonthStats) {
            const previousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            const previousMonthKey = `${previousMonth.getFullYear()}-${(previousMonth.getMonth() + 1).toString().padStart(2, '0')}`;
            const previousMonthStats = statsMap.get(previousMonthKey);
            currentMonthStats = {
                month: monthKey,
                data: {
                    facebook: previousMonthStats ? previousMonthStats.data.facebook : 0,
                    instagram: previousMonthStats ? previousMonthStats.data.instagram : 0,
                    linkedin: previousMonthStats ? previousMonthStats.data.linkedin : 0,
                    gmb: previousMonthStats ? previousMonthStats.data.gmb : 0
                }
            };
        }

        currentMonthStats.data[network] = (currentMonthStats.data[network] || 0) + 1;
        statsMap.set(monthKey, currentMonthStats);

    }, []);

    useEffect(() => {
        let allConnections: Connection[] = [];

        userData.forEach(user => {
            if (user.linkedin) {
                allConnections.push({ date: user.linkedin, network: "linkedin" });
            }
            if (user.fb) {
                allConnections.push({ date: user.fb, network: "facebook" });
            }
            if (user.insta) {
                allConnections.push({ date: user.insta, network: "instagram" });
            }
            if (user.gmb) {
                allConnections.push({ date: user.gmb, network: "gmb" });
            }
        });

        // Trier les connexions par date
        allConnections.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        // Agréger les données
        const newMonthlyStatsMap = new Map<string, MonthlyStats>();
        allConnections.forEach(connection => {
            aggregateConnection(connection.date, connection.network, newMonthlyStatsMap);
        });

        setMonthlyStatsMap(newMonthlyStatsMap);
    }, [userData, aggregateConnection]);

    const chartData = Array.from(monthlyStatsMap.values()).sort((a, b) => {
        const dateA = new Date(a.month);
        const dateB = new Date(b.month);
        return dateA.getTime() - dateB.getTime();
    }).map((stats) => {
        return {
            month: stats.month,
            facebook: stats.data.facebook,
            instagram: stats.data.instagram,
            linkedin: stats.data.linkedin,
            gmb: stats.data.gmb
        };
    });

    const getMonthName = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString("fr-FR", { month: "short" });
    };

    return (
        <>
        <div ref={chartDiv} className="publication-mediumBox">
            <Spinner loading={!chartData}/>
            <div className="long-box">
                <div className="box-title">
                    <img src={publicationLogo} alt="logo publication"/>
                    <div className="title">
                        Evolution des connexions
                    </div>
                </div>
                { chartData && (
                    <div
                         className="chart-connexion">
                        <ResponsiveContainer width="100%">
                            <BarChart
                                data={chartData}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" tickFormatter={(month) => getMonthName(month)} />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="facebook" stackId="a" fill="#1773EA" />
                                <Bar dataKey="instagram" stackId="a" fill="#00A0DC" />
                                <Bar dataKey="linkedin" stackId="a" fill="#FFDC7D" />
                                <Bar dataKey="gmb" stackId="a" fill="#F23526" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                )}
            </div>
        </div>
        </>
    );
}

export default Statistics;
